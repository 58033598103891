import React, { useEffect } from "react";
import { Navbar } from "./components/Navbar/Navbar";
import { LoadPage } from "./components/LoadPage";
import { rawGalleries } from "./resources/rawGalleries";

import { projectText } from "./resources/all_texts";
import json_data from "./directories_structure.json";
import { EnlargedImage } from "./components/EnlargedImage";

function App() {
  const images_folder = "/resources/Galleries/";

  const [allGalleries, setAllGalleries] = React.useState(rawGalleries);
  function toggleSelectedMenuItem(id) {
    setAllGalleries(prevGalleries => {
      return prevGalleries.map(gallery => {
        return gallery[0] === id
          ? { ...gallery, selected: true }
          : { ...gallery, selected: false };
      });
    });
  }

  const [gallery, setGallery] = React.useState("Home");
  function changeGallery(gallery_name) {
    setGallery(gallery_name);
    toggleSelectedMenuItem(gallery_name);
  }

  const isProject = gallery.slice(4).startsWith("Project");
  const projectName = gallery.slice(14);

  const [largeImage, setLargeImage] = React.useState({
    imgName: "",
    source: "",
    description: "",
  });
  function enlargeImage(imageName) {
    const imageSource = imageName
      ? `${images_folder}${gallery}/${imageName}`
      : "";
    const imageDescription = imageName
      ? json_data[gallery][imageName].description
      : "";
    setLargeImage(prevLargeImage => {
      return {
        ...prevLargeImage,
        imgName: imageName,
        source: imageSource,
        description: imageDescription,
      };
    });
  }

  const [imagesInGallery, setImagesInGallery] = React.useState(
    Object.entries(json_data["Home"])
  );
  React.useEffect(() => {
    json_data[gallery] &&
      setImagesInGallery(Object.entries(json_data[gallery]));
  }, [gallery]);

  let hasPrev;
  let hasNext;
  React.useEffect(() => {
    if (largeImage.imgName) {
      const largeImageIndex = findLargePhotoIndex();
      hasPrev = largeImageIndex >= 1;
      hasNext = largeImageIndex < imagesInGallery.length - 1;
    }
  }, [largeImage]);

  function findLargePhotoIndex() {
    if (largeImage.imgName) {
      for (let i = 0; i < imagesInGallery.length; i++) {
        if (imagesInGallery[i][0] === largeImage.imgName) {
          return i;
        }
      }
    }
  }

  const handleKeyPress = React.useCallback(
    event => {
      if (event.key === "Escape") {
        enlargeImage("", "", "");
      } else {
        event.key === "ArrowLeft"
          ? hasPrev && goToPrevOrNextImage("prev")
          : event.key === "ArrowRight" &&
            hasNext &&
            goToPrevOrNextImage("next");
      }
    },
    [largeImage, setLargeImage]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);

  const goToPrevOrNextImage = React.useCallback(wantedImage => {
    const wantedImageIndex =
      wantedImage === "prev"
        ? findLargePhotoIndex() - 1
        : findLargePhotoIndex() + 1;
    enlargeImage(imagesInGallery[wantedImageIndex][0]);
  });

  // SWIPE DETECTION
  const swipeSensitivity = 75;
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const calculateSwipe = () => {
    const swipeDirection = touchStart - touchEnd;
    // TODO: The following is a hack. Find out why calling "goToPrevOrNextImage" directly isn't working.
    const event = { key: "" };
    if (swipeDirection < swipeSensitivity * -1) {
      event.key = "ArrowLeft";
    } else if (swipeDirection > swipeSensitivity) {
      event.key = "ArrowRight";
    }
    event.key && handleKeyPress(event);
  };

  return (
    <main className="main">
      <React.StrictMode>
        <Navbar allGalleries={allGalleries} handleClick={changeGallery} />
        {largeImage.source && (
          <EnlargedImage
            handleKeyPress={handleKeyPress}
            calculateSwipe={calculateSwipe}
            largeImageSrc={largeImage.source}
            largeImageDescription={largeImage.description}
            enlargeImage={enlargeImage}
            setTouchStart={setTouchStart}
            setTouchEnd={setTouchEnd}
          />
        )}
        <div className="gallery-view">
          {isProject && projectText[projectName] && (
            <div className="gallery-view__project">
              <h1 className="gallery-view__project__name">{projectName}</h1>
              <hr />
              <p>{projectText[projectName]}</p>
              <hr />
            </div>
          )}
          <LoadPage
            gallery_folder={`${images_folder}${gallery}`}
            gallery_name={gallery}
            enlargeImage={enlargeImage}
          />
        </div>
      </React.StrictMode>
    </main>
  );
}

export default App;
