import Image from "next/image";
import React from "react";

interface ImageCardProps {
  imageName: string;
  src: string;
  description: string;
  gallery: string;
  enlargeImage: (imageName: string) => void;
}

export const ImageCard: React.FC<ImageCardProps> = ({
  imageName,
  src,
  description,
  gallery,
  enlargeImage,
}) => {
  const gallery_class = gallery === "Home" ? "home" : "image-thumb";
  return (
    <div className="image-card">
      <Image
        src={src}
        alt={description}
        className={gallery_class}
        onClick={() => enlargeImage(imageName)}
        width={400}
        height={400}
      />
    </div>
  );
};

ImageCard.displayName = "ImageCard";
