import Head from "next/head";
import React, { Fragment } from "react";
import About from "../../pages/About";
import Contact from "../../pages/Contact";

import json_data from "../directories_structure.json";
import { ImageCard } from "./ImageCard";

interface LoadPageProps {
  gallery_folder: string;
  gallery_name: string;
  enlargeImage: (imageName: string) => void;
}

export const LoadPage: React.FC<LoadPageProps> = ({
  gallery_folder,
  gallery_name,
  enlargeImage,
}) => {
  if (gallery_name === "About") {
    return <About />;
  } else if (gallery_name === "Contact") {
    return <Contact />;
  } else {
    const galleryNameSliced =
      gallery_name === "Home"
        ? "Nando Machado Photography"
        : gallery_name.slice(4);
    return (
      <>
        {Object.entries(json_data[gallery_name]).map((image: Object) => {
          return (
            <Fragment key={image[0]}>
              <Head>
                <title>{galleryNameSliced}</title>
                <meta name="gallery" content={galleryNameSliced} />
              </Head>
              <ImageCard
                key={image[0]}
                imageName={image[0]}
                src={`${gallery_folder}/${image[0]}`}
                gallery={gallery_name}
                description={image[1].description}
                enlargeImage={enlargeImage}
              />
            </Fragment>
          );
        })}
      </>
    );
  }
};

LoadPage.displayName = "LoadPage";
