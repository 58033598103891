import React from "react";
import { GalleryObject } from "../../resources/gallery.type";

interface MenuItemProps {
  gallery: GalleryObject;
  handleClick: (galleryName: string) => void;
  hideHamburger: (arg0: boolean) => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  gallery,
  handleClick,
  hideHamburger,
}) => {
  const folder_name_in_projects = "Project";
  const isSelected = gallery.selected ? "selected" : "unselected";

  let gallery_name =
    gallery[0] === "About" || gallery[0] === "Contact"
      ? gallery[0]
      : gallery[0].slice(4);

  let isProject: boolean;
  // TODO: bundle projects into one dropdown menu item
  if (gallery_name.startsWith(folder_name_in_projects)) {
    gallery_name = gallery_name.slice(10);
    isProject = true;
  } else {
    isProject = false;
  }

  return (
    <li
      key={gallery[0]}
      className={isSelected}
      onClick={() => {
        handleClick(gallery[0]);
        hideHamburger(false);
      }}
    >
      {gallery_name}
      {isProject && <div className="project-menu-item">PROJECT</div>}
    </li>
  );
};

MenuItem.displayName = "MenuItem";
