export const aboutText = `
            <p>I discovered my passion for photography in London, where I live today. Coming from an analogue photography and darkroom background, I studied photography in Dublin, Ireland, and Verona, Italy.</p>
            <p><p>
            <p>With a background in psychology, my previous work focused on the human condition before I turned to landscapes and travel.</p>
            <p></p>
            <p>Although my portfolio consists of nature and scenery, 
            I am particularly interested in environmental causes such as animal rights and ecological issues. 
            The project that generated the most exposure related to such causes was a commission for a UK-based animal protection charity, 
            which resulted in the publication of two peer-reviewed papers (<a href="https://natureconservation.pensoft.net/article/17369/element/8/22689/" target="__blank" rel="noopener noreferrer">Nature Conservation</a> and <a href="https://www.mdpi.com/2076-2615/8/11/216/htm" target="__blank" rel="noopener noreferrer">Animals</a>)
            and international exposure in several publications (see below).
            
            In 2020, with renewed interest in the impact of wildlife selfies, France TV used some of my images to create 
            <a href="https://www.facebook.com/watch/?v=890175044795954">an educational video</a> about the phenomenon. 
            
            The latest publications to showcase my work, in 2021, were the scientific journal Global Ecology and Conservation and National Geographic.</p>
        
            <p>Due to leaders around the world becoming increasingly aggressive towards environmentalists, I have ceased working as a photographer and now work as a software developer. Ultimately, I intend to find novel ways to employ tech to help support environmental causes.</p>
        `;

export const publications = [
  [
    "https://www.nationalgeographic.com/photography/proof/2017/10/wildlife-watch-amazon-ecotourism-animal-welfare/",
    "National Geographic (2017, only the videos are mine, not the photos)",
  ],
  ["https://www.bbc.co.uk/news/technology-41482204", "BBC (2017, UK)"],
  [
    "https://www.theguardian.com/travel/2017/oct/04/wild-animal-selfies-charity-condemns-trend-brazi-peru-animal-cruelty",
    "Guardian (2017, UK)",
  ],
  [
    "http://www.freemalaysiatoday.com/category/leisure/2017/10/05/activists-condemn-craze-for-wild-animal-selfies/",
    "FMT News (2017, Malaysia)",
  ],
  [
    "http://www.cbc.ca/news/technology/wildlife-selfies-good-and-bad-1.4340944",
    "CBC (2017, Canada)",
  ],
  [
    "https://www.stuff.co.nz/travel/news/109081631/ethical-tourist-wildlife-experiences-seven-animal-experiences-tourists-should-never-do",
    "Stuff (2018, New Zealand)",
  ],
  [
    "https://www.asahi.com/ajw/articles/13055208",
    "Asahi Shimbum (2019, Japan)",
  ],
  [
    "https://www.theguardian.com/environment/2020/jun/02/why-cant-we-leave-them-alone-the-troubling-truth-about-selfies-with-sloths-aoe",
    "Guardian (2020, UK)",
  ],
  [
    "https://www.sciencedirect.com/science/article/pii/S2351989421001815?utm_campaign=STMJ_AUTH_SERV_PUBLISHED&amp;utm_medium=email&amp;utm_acid=229636104&amp;SIS_ID=&amp;dgcid=STMJ_AUTH_SERV_PUBLISHED&amp;CMX_ID=&amp;utm_in=DM150372&amp;utm_source=AC_",
    "Global Ecology and Conservation (2021, peer-reviewed paper)",
  ],
  [
    "https://www.nationalgeographic.com/animals/article/notorious-wildlife-market-largest-in-peruvian-amazon-back-in-business-after-pandemic-hiatus?cmpid=org=ngp::mc=social::src=facebook::cmp=editorial::add=fb20211012animals-southamericawildlifemarket&sf250297590=1&fbclid=IwAR2dApQaVKaz6zoXPh1z00IYoiEU3QebbBiSgUuNZtjMeOSALqawlC8sFeY",
    "National Geographic (2021)",
  ],
];

export const projectText = {
  "Wildlife Selfies": `   This project is the result of a three-month long commission in the rainforest in Brazil and Peru for World Animal Protection involving photos, videos and semi-structured interviews. 

                    During this assignment it became apparent that tourists join tours to engage in eco-activities, unaware that the activities are often unregulated, illegal and cruel. Animals used in these activities die due to mishandling and severe, constant stress. Tour guides who are either lying or are being lied to themselves pre-amp possible concerns by assuring tourists that these activities are harmless to the animals and that they help the local communities.

                    The assignment resulted in two scientific papers (one published, another currently being revised) on the social, economical and environmental impact of ecotourism and the "selfie" phenomenon on local wildlife and communities. The focus of the published paper was the impact of eco-tourism on animal welfare in the Amazon area of Manaus, Brazil.
                `,
  "The People of Setto": `   A small collections of portraits of the people of Setto, a very small hunting community in Togo. 
            
                These very friendly people welcomed me and a colleague into their homes during our research into how hunting communities interacted with the local wildlife.
            `,
};
