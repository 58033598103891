import json_data from "../directories_structure.json";

// Reads from json_data and adds a "selected" attribute
export const rawGalleries = Object.entries(json_data)
  .filter(gallery => gallery[0] !== "Home")
  .concat([
    ["About", { About: { description: "", position: 0, selected: false } }],
    ,
    [
      "Contact",
      {
        Contact: {
          description: "",
          position: 0,
          selected: false,
        },
      },
    ],
  ]);
