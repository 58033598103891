import Head from "next/head"
import React, { Fragment } from "react"
import App from "../src/App"

function Index() {
    return (
        <Fragment>
            <Head>
                <meta 
                    name="description" 
                    content="Nando Machado's photography portfolio featuring landscape, travel and environmental work, both published and unpublished. Author: Nando Machado. All rights reserved"
                />
                <title>Nando Machado Photography</title>
            </Head>
            <App />
        </Fragment>
    )
}

export default Index