import Image from "next/image";
import React from "react";

interface EnlargedImageProps {
  handleKeyPress: (event: any) => void;
  calculateSwipe: () => void;
  largeImageSrc: string;
  largeImageDescription: string;
  enlargeImage: (arg0: string, arg1: string, arg2: string) => void;
  setTouchStart: (arg0: number) => void;
  setTouchEnd: (arg0: number) => void;
}

export const EnlargedImage: React.FC<EnlargedImageProps> = ({
  handleKeyPress,
  calculateSwipe,
  largeImageSrc,
  largeImageDescription,
  enlargeImage,
  setTouchStart,
  setTouchEnd,
}) => {
  const closeEnlargedImage = () => {
    enlargeImage("", "", "");
  };

  const className = "enlarged-img";
  return (
    <>
      <div
        className={`${className}__container`}
        onKeyDown={() => handleKeyPress}
        onTouchStart={touchStartEvent =>
          setTouchStart(touchStartEvent.targetTouches[0].clientX)
        }
        onTouchMove={touchMoveEvent =>
          setTouchEnd(touchMoveEvent.targetTouches[0].clientX)
        }
        onTouchEnd={() => calculateSwipe()}
      >
        <img
          className={`${className}__close-btn`}
          src="../resources/visuals/close_img.png"
          onClick={closeEnlargedImage}
        />
        <Image
          className={`${className}__image`}
          src={largeImageSrc}
          alt={largeImageDescription}
          layout="fill"
        />
      </div>
      {largeImageDescription && (
        <div>
          {/* TODO: Make the info button a toggle for mobile instead of activate */}
          <button className="enlarged-img__show-description-btn">ⓘ</button>
          <div className="enlarged-img__description__container">
            <span className="enlarged-img__description__text">
              {largeImageDescription}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

EnlargedImage.displayName = "EnlargedImage";
