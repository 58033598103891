import Head from "next/head";
import React, { Fragment } from "react";

function Contact() {
    return(
        <Fragment>
            <Head>
                <title>Nando Machado Photography - Contact</title>
                <meta name="Contact page" content="Nando Machado photography's contact page" />
            </Head>
            <div className = "contact">
                <div className="contact--text">
                    Please send any enquiries to
                </div>
                <a href="mailto:nandomachadophotography@gmail.com" className="contact--email">NandoMachadoPhotography@gmail.com</a>
            </div>
        </Fragment>
    )
}

export default Contact