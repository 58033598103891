import React from "react";
import { GalleryObject } from "../../resources/gallery.type";
import { MenuItem } from "./MenuItem";

interface MenuProps {
  allGalleries: GalleryObject[];
  handleClick: (galleryName: string) => void;
  hideHamburger: () => void;
}

export const Menu: React.FC<MenuProps> = ({
  allGalleries,
  handleClick,
  hideHamburger,
}) => {
  const galleries = allGalleries.map(gallery => (
    <MenuItem
      key={gallery[0]}
      gallery={gallery}
      handleClick={handleClick}
      hideHamburger={hideHamburger}
    />
  ));
  return <ul id="menu">{galleries}</ul>;
};

Menu.displayName = "Menu";
