import React from "react";
import { GalleryObject } from "../../resources/gallery.type";
import { Menu } from "./Menu";

interface NavBarProps {
  handleClick: (galleryName: string) => void;
  allGalleries: GalleryObject[];
}

export const Navbar: React.FC<NavBarProps> = ({
  handleClick,
  allGalleries,
}) => {
  function hideHamburger() {
    const hamburgerCheckbox = document.getElementById(
      "hamburger-menu--container"
    ) as HTMLInputElement;
    hamburgerCheckbox.checked = false;
  }

  const logo = "../resources/visuals/logo_subtitle.png";
  const hamburgerMenuIcon = "../resources/visuals/hamburger_menu.png";

  return (
    <nav id="navbar">
      <img
        src={logo}
        alt=""
        id="logo"
        onClick={() => {
          handleClick("Home");
        }}
      />
      <input type="checkbox" id="hamburger-menu--container" />
      <label className="btn" htmlFor="hamburger-menu--container">
        <img src={hamburgerMenuIcon} alt="" className="hamburger-menu--icon" />
      </label>
      <Menu
        allGalleries={allGalleries}
        handleClick={handleClick}
        hideHamburger={hideHamburger}
      />
    </nav>
  );
};

Navbar.displayName = "Navbar";
