import Head from "next/head";
import React, { Fragment } from "react";
import {aboutText, publications} from "../src/resources/all_texts";

function About() {

    const returnPublications = 
        publications.map(publication => {
            return (
                <li key={ publication[0] }>
                    <a href={ publication[0] } target="__blank" rel="noopener noreferrer">{ publication[1] }</a>
                </li>
            )
        })

    return(
        <Fragment>
            <Head>
                <title>Nando Machado Photography - About</title>
                <meta name="About page" content="Nando Machado photography's about page, with brief bio and link to published work" />
            </Head>
            <div className = "about-text">
                <div className = "about-text--main" dangerouslySetInnerHTML = { { __html: aboutText } } />
                <ul className = "publication-items">{ returnPublications }</ul>
                <hr />
            </div>
        </Fragment>
    )
}

export default About